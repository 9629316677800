<template>
    <div>
        <div class="music-action">
            <audio ref="audio" @timeupdate="onTimeUpdate">
                <source :src="currentMusic.url" type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
            <div>
                <i class="music-icon bl-icon bl-icon-Music-1"></i>
                <i class="music-action-icon bl-icon" :class="`bl-icon-music-${model}`" @click="onModel"></i>
                <i
                    class="music-action-icon bl-icon bl-icon-music-previous"
                    :class="{ disabled: !preAble }"
                    @click="onPre"
                ></i>
                <i
                    class="music-action-icon bl-icon"
                    :class="[isPlaying ? 'bl-icon-music-pause' : 'bl-icon-music-play']"
                    @click="onPlayOrPause"
                ></i>
                <i class="music-action-icon bl-icon bl-icon-music-next" @click="onNext"></i>
            </div>
        </div>
    </div>
</template>
<script>
import { request } from '@/blvue/request';

export default {
    name: 'BlMusicAction',
    props: {
        musicLyric: {
            type: Object,
            required: false
        }
    },
    computed: {
        isLoopModel() {
            return this.model === 'loop';
        },
        isRandomModel() {
            return this.model === 'random';
        },
        isSingleModel() {
            return this.model === 'single';
        },
        preAble() {
            return this.usedIndex.length > 1;
        }
    },
    data() {
        return {
            isPlaying: false, //音乐播放状态
            model: 'loop', //默认列表循环
            musics: [],
            currentMusicIndex: 0,
            currentMusic: {},
            usedIndex: []
        };
    },
    mounted() {
        const self = this;
        self.loadAllMusic();
        // 加载歌词内容并解析
        self.$refs.audio.addEventListener('play', function () {
            self.isPlaying = true;
        });
        self.$refs.audio.addEventListener('ended', function () {
            self.isPlaying = false;
            self.onNext();
        });
    },
    methods: {
        async loadAllMusic() {
            const self = this,
                url = '/master/music/listMusics';
            const result = await request.get(url);
            if (result.status === 200 && result.data.length > 0) {
                self.musics.length = 0;
                result.data.forEach((item) => {
                    self.musics.push({ url: item.url, lrc: item.lrc, name: item.name });
                });
                self.currentMusic = self.musics[self.currentMusicIndex];
                self.$refs.audio.src = self.currentMusic.url;
            }
        },
        onModel() {
            const self = this;
            if (self.isLoopModel) {
                self.model = 'random';
            } else if (self.isRandomModel) {
                self.model = 'single';
            } else {
                self.model = 'loop';
            }
        },
        onPlayOrPause(isPre) {
            const self = this,
                audio = self.$refs.audio;
            if (this.isPlaying) {
                audio.pause();
            } else {
                this.$emit('playing', self.currentMusic.name);
                self.musicLyric.loadLRC(self.currentMusic);
                audio.play().catch((error) => {
                    console.error('播放失败:', error);
                });
            }
            this.isPlaying = !this.isPlaying;
            if (!self.usedIndex.includes(self.currentMusicIndex) && !isPre) {
                self.usedIndex.push(self.currentMusicIndex);
            }
        },
        onPre() {
            const self = this,
                { $refs } = self;
            if (self.preAble) {
                self.currentMusicIndex = self.getMusicIndex(false);
                self.currentMusic = self.musics[self.currentMusicIndex];
                $refs.audio.src = self.currentMusic.url;
                self.isPlaying = false;
                self.onPlayOrPause(true);
            }
        },
        /**
         * @param music {url:文件路径, lrc:歌词路径}
         */
        onNext(data, music) {
            const self = this,
                { $refs } = self;
            if (music !== null && music !== undefined) {
                self.currentMusic = music;
            } else {
                self.currentMusicIndex = self.getMusicIndex(true);
                self.currentMusic = self.musics[self.currentMusicIndex];
            }
            $refs.audio.src = self.currentMusic.url;
            self.isPlaying = false;
            self.onPlayOrPause();
        },
        getMusicIndex(isNext) {
            const self = this;
            if (self.isSingleModel) {
                return self.currentMusicIndex;
            }
            if (!isNext && self.usedIndex.length > 1) {
                self.usedIndex.pop();
                return self.usedIndex[self.usedIndex.length - 1];
            }
            if (self.isLoopModel) {
                let musicIndex = self.currentMusicIndex;
                if (isNext) {
                    musicIndex++;
                    if (musicIndex >= self.musics.length) {
                        musicIndex = 0;
                    }
                } else {
                    musicIndex--;
                    if (musicIndex < 0) {
                        musicIndex = self.musics.length - 1;
                    }
                }
                return musicIndex;
            }
            //随机模式
            if (self.usedIndex.length === self.musics.length) {
                self.usedIndex = [];
            }
            let indices = Array.from({ length: self.musics.length }, (_, i) => i); // 初始化索引数组
            const availableIndices = indices.filter((index) => !self.usedIndex.includes(index));
            return availableIndices[Math.floor(Math.random() * availableIndices.length)]; // 返回随机索引
        },
        async loadMusic(musicName) {
            const self = this,
                url = '/master/music/getMusic';
            const result = await request.get(url, { name: musicName });
            const music = result.data;
            if (music !== null) {
                self.onNext(null, music);
            }
        },
        onTimeUpdate() {
            const self = this,
                audio = self.$refs.audio;
            if (self.musicLyric != null) {
                self.musicLyric.onTimeUpdate(audio.currentTime);
            }
        }
    }
};
</script>

<style>
.music-action-icon {
    font-weight: 900;
    font-size: larger;
    margin-left: 18px; /* 为图标与内容之间添加一些间距 */
    cursor: pointer; /* 鼠标悬停时显示手型光标 */
    color: var(--text-color);
}

.music-action {
    display: flex;
    font-weight: 900;
    color: var(--text-color);
    bottom: 0; /* 距离底部0像素 */
    padding: 6px 2px;
    background-color: var(--primary-color);
    text-align: center;
    border-radius: 15px;
    user-select: none;
    z-index: 1000; /* 确保按钮在其他内容上方 */
    justify-content: center;
}

.bl-icon-music-previous.disabled {
    opacity: 0.5; /* 降低透明度 */
    cursor: not-allowed; /* 禁用鼠标指针 */
    pointer-events: none; /* 禁止点击事件 */
}
</style>
