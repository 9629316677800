var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"platform"},[_c('div',{ref:"platformCont",staticClass:"round_div_all"},_vm._l((_vm.platforms),function(item){return _c('div',{key:item.name},[_c('div',{staticClass:"round_div"},[_c('div',{staticClass:"r-s01"},[_c('a',{attrs:{"href":item.url,"target":"_blank"}},[(!item.logo)?_c('i',{staticClass:"bl-icon icon-platform",class:`bl-icon-website1`}):(item.logoType === 'FONT')?_c('i',{staticClass:"bl-icon icon-platform",class:`bl-icon-${item.logo}`}):_c('i',{staticClass:"bl-icon icon-platform",style:({
                                    width: '1em',
                                    height: '1em',
                                    borderRadius: '50%',
                                    backgroundImage: `url(${item.logo})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat'
                                })})])]),_c('div',{staticClass:"bl-cont"},[_c('div',{staticClass:"r-s03"},[_vm._v(_vm._s(item.theme))]),_c('div',{staticClass:"r-s02"},[_vm._v(_vm._s(item.name))])])])])}),0)]),_c('div',{ref:"squareCont",staticClass:"mian01"},_vm._l((_vm.sites),function(item){return _c('div',{key:item.name},[_c('div',{staticClass:"square_div"},[_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_c('div',{staticClass:"bl-cont squareCont"},[_c('div',{staticClass:"s-s01"},[(!item.logo)?_c('i',{staticClass:"bl-icon icon-link",class:`bl-icon-website1`}):(item.logoType === 'FONT')?_c('i',{staticClass:"bl-icon icon-link",class:`bl-icon-${item.logo}`}):_c('i',{staticClass:"bl-icon icon-link",style:({
                                    width: '1em',
                                    height: '1em',
                                    borderRadius: '50%',
                                    backgroundImage: `url(${item.logo})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat'
                                })})]),_c('div',{staticClass:"bl-cont"},[_c('div',{staticClass:"s-s02"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"s-s03"},[_vm._v(_vm._s(item.theme))])])])])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }