import axios from 'axios';

export class Request {
    static app_server = 'http://www.blove.site';

    // static app_server = 'http://192.168.11.60:8082';

    get(url, params = {}, options = {}) {
        const requestOptions = {
            ...options
            // headers: {
            //     'Content-Type': 'application/json' // 设置请求头为 JSON 类型
            // }
        };
        requestOptions.params = { ...params };
        return axios.get(Request.app_server + url, requestOptions);
    }

    post(url, params = {}, options = {}) {
        const requestOptions = {
            ...options,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };
        return axios.post(Request.app_server + url, params, requestOptions);
    }
}

export const request = new Request();
