<template>
    <div id="app">
        <home-page />
    </div>
</template>

<script>
import HomePage from '@/views/home.vue';

export default {
    name: 'App',
    components: {
        HomePage
    }
};
</script>
<style lang="css">
@import '@/assets/css/default.css';

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*text-align: center;*/
    color: #2c3e50;
    /*margin-top: 4vw;*/
}

.audio-control {
    position: fixed; /* 固定位置 */
    bottom: 0; /* 距离底部0像素 */
    left: 50%; /* 水平居中 */
    transform: translateX(-50%); /* 水平居中调整 */
    padding: 10px 0;
    background-color: #42b983;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 15px;
    user-select: none;
    z-index: 1000; /* 确保按钮在其他内容上方 */
    width: 100%;
}
</style>
