<template>
    <div>
        <div class="lyrics">
            <div
                v-for="(line, index) in lyrics"
                :key="index"
                :class="{ active: currentIndex === index }"
                ref="lyricLines"
            >
                <div class="original">{{ line.text }}</div>
                <div class="translation" v-if="index % 2 === 1">{{ line.text }}</div>
            </div>
        </div>
        <div class="bottom">
            <div class="banquan">
                <audio ref="audio" @timeupdate="onTimeUpdate">
                    <source :src="musics[0]" type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>
                <!--                <audio ref="audio" :src="songUrl" @timeupdate="onTimeUpdate"></audio>-->
                <div>
                    <i class="music-icon bl-icon bl-icon-Music-1"></i>
                    <i class="music-action-icon bl-icon bl-icon-skip-previous-fill"></i>
                    <i v-if="isPlaying" class="music-action-icon bl-icon bl-icon-pause1" @click="onPlayOrPause"></i>
                    <i v-else class="music-action-icon bl-icon bl-icon-play-arrow-fill" @click="onPlayOrPause"></i>
                    <i class="music-action-icon bl-icon bl-icon-skip-next-fill" @click="onNext"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import getLRC from './parseLRC.js'; // 将歌词解析独立为模块

export default {
    name: 'BlMusic',
    data() {
        return {
            isPlaying: false, //音乐播放状态
            musics: [
                'Nothing.s Gonna Stop Us Now-Starship.mp3',
                'https://blove.cpolar.top/mirror/music/take%20me%20to%20your%20hert.mp3'
            ],
            currentMusicIndex: 0,
            lrcs: ['Nothing’s Gonna Stop Us Now-Starship.lrc', 'Take Me to Your Heart-Michael Learns To Rock.lrc'],
            songUrl: 'Nothing.s Gonna Stop Us Now-Starship.mp3', // 歌曲文件路径
            lrcContent: '', // 歌词内容从外部加载
            lyrics: [],
            currentIndex: 0 // 当前歌词行索引
        };
    },
    mounted() {
        const self = this;
        // 加载歌词内容并解析
        self.$refs.audio.addEventListener('play', function () {
            self.isPlaying = true;
        });
        self.$refs.audio.addEventListener('ended', function () {
            self.isPlaying = false;
            self.onNext();
        });
    },
    methods: {
        onPlayOrPause() {
            const self = this;
            const audio = this.$refs.audio;
            if (this.isPlaying) {
                audio.pause();
            } else {
                self.loadLRC(self.lrcs[self.currentMusicIndex]);
                audio.play().catch((error) => {
                    console.error('播放失败:', error);
                });
            }
            this.isPlaying = !this.isPlaying;
        },
        onNext(musicSrc) {
            const self = this,
                { $refs } = self;
            let currentSrc,
                musicIndex = self.currentMusicIndex;
            if (typeof musicSrc === 'string') {
                currentSrc = musicSrc;
            } else {
                musicIndex++;
                if (musicIndex >= self.musics.length) {
                    musicIndex = 0;
                }
                self.currentMusicIndex = musicIndex;
                currentSrc = self.musics[musicIndex];
            }
            $refs.audio.src = currentSrc;
            self.isPlaying = false;
            self.onPlayOrPause();
        },
        loadLRC(url) {
            const self = this;
            fetch(url)
                .then((response) => response.text())
                .then((content) => {
                    if (content !== undefined) {
                        self.lyrics = self.parseLRC(content);
                    } else {
                        self.lyrics = [];
                    }
                });
        },
        parseLRC(lrcContent) {
            const lines = lrcContent.split('\n');
            const lyrics = [];

            const timeRegEx = /\[(\d{2}):(\d{2}\.\d{1,3})\]/; // 匹配时间戳格式

            lines.forEach((line) => {
                const match = line.match(timeRegEx);
                if (match) {
                    const time = parseInt(match[1], 10) * 60 + parseFloat(match[2]); // 转为秒数
                    const text = line.replace(timeRegEx, '').trim(); // 去除时间戳部分
                    if (text) {
                        lyrics.push({ time, text });
                    }
                }
            });

            // 为每行歌词计算结束时间（当前行结束时间为下一行的开始时间）
            for (let i = 0; i < lyrics.length - 1; i++) {
                lyrics[i].endTime = lyrics[i + 1].time;
            }
            lyrics[lyrics.length - 1].endTime = Infinity; // 最后一行没有结束时间

            return lyrics;
        },
        onTimeUpdate() {
            const audio = this.$refs.audio;
            const currentTime = audio.currentTime;

            // 使用二分查找定位当前歌词行
            let low = 0;
            let high = this.lyrics.length - 1;
            while (low <= high) {
                const mid = Math.floor((low + high) / 2);
                if (currentTime >= this.lyrics[mid].time && currentTime < this.lyrics[mid].endTime) {
                    this.currentIndex = mid;
                    this.scrollToCurrentLyric();
                    return;
                } else if (currentTime < this.lyrics[mid].time) {
                    high = mid - 1;
                } else {
                    low = mid + 1;
                }
            }
        },
        scrollToCurrentLyric() {
            const currentLine = this.$refs.lyricLines[this.currentIndex];
            if (currentLine) {
                currentLine.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }
};
</script>
<style>
.lyrics {
    height: 100%;
    text-align: center;
    background-color: #f5f5f5;
    overflow-y: auto;
}

.lyrics::-webkit-scrollbar {
    display: none; /* Webkit 浏览器隐藏滚动条 */
}

.lyrics div {
    padding: 5px;
    font-size: 16px;
}

.lyrics div.active .original {
    color: red;
    font-weight: bold;
}

.lyrics div.active .translation {
    color: darkorange;
    font-weight: bold;
}

.original {
    font-size: 16px;
    color: gray;
}

.translation {
    font-size: 14px;
    color: gray;
}
</style>
