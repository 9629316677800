<template>
    <div class="outer-container">
        <div class="header"></div>
        <div class="content">
            <div class="left">
                <div class="tool">
                    <input
                        type="text"
                        ref="name"
                        v-model="musicName"
                        placeholder="请输入歌曲名称"
                        @keydown.enter="onSearch"
                    />
                    <a href="#" @click="onSearch" role="button">查找</a>
                </div>
                <bl-music-lyric ref="musicLyric" />
            </div>
            <div class="center">
                <blove-page />
                <site-page />
            </div>
            <div class="right">
                <div></div>
            </div>
        </div>
        <div class="footer">
            <footer>
                <span class="f82">安全 | 服务条款 | 使用规范 | 客服中心 | 隐私保护指引</span><br />
                <span class="f75">Copyright © 1998-2025 All Rights Reserved.</span>
            </footer>
            <bl-music-action ref="musicAction" :music-lyric="musicLyric" @playing="playHandle" />
        </div>
    </div>
</template>
<script>
import BlMusic from '@/blvue/components/music/music.vue';
import BlovePage from '@/views/blove.vue';
import BlMusicAction from '@/blvue/components/music/music-action.vue';
import SitePage from '@/views/site/site.vue';
import BlMusicLyric from '@/blvue/components/music/music-lyric.vue';

export default {
    name: 'HomePage',
    // eslint-disable-next-line vue/no-unused-components
    components: { BlMusicLyric, SitePage, BlMusicAction, BlovePage, BlMusic },
    data() {
        return {
            musicLyric: this.$refs.musicLyric,
            musicName: void 0
        };
    },
    mounted() {
        this.musicLyric = this.$refs.musicLyric;
    },
    methods: {
        playHandle(name) {
            this.musicName = name;
        },
        onSearch() {
            if (this.musicName !== undefined) {
                this.$refs.musicAction.loadMusic(this.musicName);
            }
        }
    }
};
</script>
<style>
@import '@/assets/css/home.css';

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: var(--bg-color);
}

/* 外层容器：上中下布局 */
.outer-container {
    display: flex;
    flex-direction: column; /* 垂直方向布局 */
    height: 100vh; /* 占满整个视口高度 */
    overflow: hidden; /* 整体不滚动 */
}

.header {
    text-align: center;
}

.footer {
    border: 0.5px solid var(--border-color);
    text-align: center;
}

.content {
    display: flex;
    flex-grow: 1; /* 占据剩余空间 */
    overflow: hidden; /* 中间区域不滚动 */
}

.left,
.right {
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    justify-content: center;
    align-items: center;
    padding: 0 2px;
    max-width: 40%;
    min-width: 12em;
    width: 15%;
    overflow-y: scroll; /* 允许垂直滚动 */
}

.tool {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    margin-top: 0.3em;
}

.tool input {
    text-align: center;
    height: 25px;
    width: 70%;
    border-color: var(--primary-color);
    border-radius: 8px;
}

.tool a {
    margin-left: 8px;
    font-weight: bold;
    font-size: small;
    color: gray; /* 链接被点击时的状态 */
    text-decoration: none;
}

.tool a:hover {
    text-decoration: none;
    color: var(--hover-color); /* 链接被点击时的状态 */
}

.right {
    align-items: stretch;
}

.center {
    flex-grow: 1; /* 占据剩余空间 */
    box-sizing: border-box;
    overflow-y: scroll; /* 允许垂直滚动 */
    scrollbar-width: none; /* Firefox 隐藏滚动条 */
    -ms-overflow-style: none; /* IE 隐藏滚动条 */
}

.center::-webkit-scrollbar {
    display: none; /* Webkit 浏览器隐藏滚动条 */
}

.left::-webkit-scrollbar {
    display: none; /* Webkit 浏览器隐藏滚动条 */
}

.right::-webkit-scrollbar {
    display: none; /* Webkit 浏览器隐藏滚动条 */
}

/*适应手机端*/
@media (max-width: 600px) {
    .outer-container {
        height: 93vh; /* 占满整个视口高度 */
    }

    .left,
    .right {
        display: none;
    }

    .footer {
        border: none;
    }

    .footer footer {
        display: none;
    }
}
</style>
