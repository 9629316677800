<template>
    <div>
        <div class="platform">
            <div ref="platformCont" class="round_div_all">
                <div v-for="item in platforms" :key="item.name">
                    <div class="round_div">
                        <div class="r-s01">
                            <a :href="item.url" target="_blank">
                                <i v-if="!item.logo" class="bl-icon icon-platform" :class="`bl-icon-website1`"></i>
                                <i
                                    v-else-if="item.logoType === 'FONT'"
                                    class="bl-icon icon-platform"
                                    :class="`bl-icon-${item.logo}`"
                                ></i>
                                <i
                                    v-else
                                    class="bl-icon icon-platform"
                                    :style="{
                                        width: '1em',
                                        height: '1em',
                                        borderRadius: '50%',
                                        backgroundImage: `url(${item.logo})`,
                                        backgroundSize: 'contain',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat'
                                    }"
                                ></i>
                            </a>
                        </div>
                        <div class="bl-cont">
                            <div class="r-s03">{{ item.theme }}</div>
                            <div class="r-s02">{{ item.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div ref="squareCont" class="mian01">
            <div v-for="item in sites" :key="item.name">
                <div class="square_div">
                    <a :href="item.url" target="_blank">
                        <div class="bl-cont squareCont">
                            <div class="s-s01">
                                <i v-if="!item.logo" class="bl-icon icon-link" :class="`bl-icon-website1`"></i>
                                <i
                                    v-else-if="item.logoType === 'FONT'"
                                    class="bl-icon icon-link"
                                    :class="`bl-icon-${item.logo}`"
                                ></i>
                                <i
                                    v-else
                                    class="bl-icon icon-link"
                                    :style="{
                                        width: '1em',
                                        height: '1em',
                                        borderRadius: '50%',
                                        backgroundImage: `url(${item.logo})`,
                                        backgroundSize: 'contain',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat'
                                    }"
                                ></i>
                            </div>
                            <div class="bl-cont">
                                <div class="s-s02">{{ item.name }}</div>
                                <div class="s-s03">{{ item.theme }}</div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { request } from '@/blvue/request';

export default {
    name: 'SitePage',
    data() {
        return {
            platforms: [],
            sites: []
        };
    },
    mounted() {
        this.loadAllSite();
    },
    methods: {
        async loadAllSite() {
            const self = this,
                url = '/master/site/list';
            const result = await request.get(url);
            if (result.status === 200 && result.data.length > 0) {
                self._handleSite(result.data || []);
            }
        },
        _handleSite(records) {
            const self = this,
                oneLevel = [],
                twoLevel = [];
            records.forEach((record) => {
                if (record.level === 1) {
                    oneLevel.push(record);
                } else if (record.level === 2) {
                    twoLevel.push(record);
                }
            });
            self.platforms = oneLevel;
            self.sites = twoLevel;
        }
    }
};
</script>
<style>
.platform {
    padding: 10px;
    z-index: 1;
    position: relative;
}

.platform::after {
    background-color: var(--primary-color);
    content: '';
    width: 120%;
    height: 12em;
    z-index: 0;
    position: absolute;
    top: 0;
    border-radius: 0 0 80% 80%;
    left: 50%;
    transform: translateX(-50%); /* 水平居中 */
}

.round_div_all {
    z-index: 2;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    display: flex; /* 定义内部为弹性布局 */
    flex-wrap: wrap; /* 让弹性盒元素在需要的时候拆列 */
    justify-content: space-evenly; /* （横轴）方向上均匀排列每个元素 */
}

.round_div_all .round_div {
    height: 8em;
    width: 8em;
    margin: auto;
    margin-top: 0.5em;
    border-radius: 50%;
    cursor: pointer;
    padding: 0.1em;
    background-color: var(--hover-color);
}

.round_div_all .round_div:hover {
    background-color: var(--hover-color);
    transition-duration: 0.3s; /* 缩放的过渡时间 */
}

.round_div_all .round_div .r-s01 {
    height: 40px;
    width: 40px;
    margin-top: 15px;
    margin-right: 56px;
    margin-left: auto;
    /*margin-right: auto;*/
    /*padding-left: 1.1em;*/
}

.round_div_all .round_div .r-s02 {
    height: 25px;
    width: 90px;
    margin-top: 5px;
    margin-right: auto;
    margin-left: auto;
    font-size: 1em;
    color: var(--text-color);
    text-align: center;
    font-weight: bold;
}

.round_div_all .round_div .r-s03 {
    width: 100%;
    font-size: 0.6em;
    text-align: center;
    color: #d3d3d3;
    margin-top: 15px;
    margin-right: auto;
    margin-left: auto;
}

.bl-cont {
    margin-top: 0.5em;
}

/*适应手机端*/
@media (max-width: 600px) {
    .platform::after {
        background-color: var(--bg-color);
    }
}
</style>
