<template>
    <div class="bl-container">
        <div class="bl-cont">
            <div class="logo-cont">
                <img class="logo" src="@/assets/image/logo.svg" alt="Blove logo" />
            </div>
        </div>
        <div class="bl-cont">
            <h3>Blove For You</h3>
        </div>
        <div class="bl-cont">
            <div class="top-text">
                “Blove For You”
                一种对生活的深切领悟与感恩，它教会我们在平凡中寻找非凡，于细微处发现精彩。清晨的微笑，忙碌的脚步，流淌的汗水，这些看似平淡的瞬间，承载着我们对生活的热爱与追求。泪水凝结生命的印记，汗水铸就非凡的人生。
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'BlovePage',
    data() {
        return {};
    },
    mounted() {},
    methods: {}
};
</script>
<style scoped>
.bl-container {
    padding: 5px;
    background-color: var(--primary-color);
}

.bl-cont {
    justify-content: center;
}

.bl-cont h3 {
    color: var(--default-color-gray);
}

.logo-cont {
    justify-content: center;
    width: 10vw; /* 使用相对视口宽度的单位 */
    height: 10vw; /* 使用相对视口高度的单位，保持比例 */
    max-width: 120px; /* 设置最大宽度 */
    max-height: 120px; /* 设置最大高度 */
    min-width: 80px; /* 设置最大宽度 */
    min-height: 80px; /* 设置最大高度 */
    border-radius: 50%;
    border: 2px solid var(--primary-color);
    padding: 2px;
    background: var(--bg-color);
}

.logo {
    width: 100%;
    height: 100%;
    min-width: 80px;
    min-height: 80px;
}

.logo-cont img:hover {
    opacity: 0.78;
}

.top-text {
    text-indent: 2em;
    width: 92%;
    font-size: 1em;
    border-top: 0.1em solid var(--text-color);
    align-items: center;
    color: var(--default-color-gray);
}

/*适应手机端*/
@media (max-width: 600px) {
    .bl-container {
        background-color: var(--bg-color);
    }

    .logo-cont {
        width: 6vw; /* 使用相对视口宽度的单位 */
        height: 6vw; /* 使用相对视口高度的单位，保持比例 */
        min-width: 60px; /* 设置最大宽度 */
        min-height: 60px; /* 设置最大高度 */
    }

    .logo {
        min-width: 60px;
        min-height: 60px;
    }

    .bl-cont h3 {
        color: var(--primary-color);
    }

    .top-text {
        width: 95%;
        border-top: 0.1em solid var(--border-color);
        color: var(--primary-color);
    }
}
</style>
