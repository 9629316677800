<template>
    <div class="lyrics">
        <div>
            <div class="musicTitle">{{ musicName }}</div>
        </div>
        <div v-if="musicName === undefined" class="none">想听就听...</div>
        <div v-else-if="lyrics.length === 0" class="none">暂无歌词</div>
        <div v-else>
            <div
                v-for="(line, index) in lyrics"
                :key="index"
                :class="{ active: currentIndex === index }"
                ref="lyricLines"
            >
                <div class="original" v-if="line.text">{{ line.text }}</div>
                <div class="translation" v-if="line.secondText">{{ line.secondText }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BlMusic-lyric',
    data() {
        return {
            lrcContent: '', // 歌词内容从外部加载
            lyrics: [],
            musicName: void 0,
            currentIndex: 0 // 当前歌词行索引
        };
    },
    methods: {
        loadLRC(music) {
            const self = this;
            self.musicName = music.name;
            if (!music.lrc) {
                self.lyrics = [];
            } else {
                fetch('https://blove.cpolar.top/mirror/music/' + music.lrc)
                    .then((response) => response.text())
                    .then((content) => {
                        if (content !== undefined) {
                            self.lyrics = self.parseLRC(content);
                        } else {
                            self.lyrics = [];
                        }
                    });
            }
        },
        parseLRC(lrcContent) {
            const self = this,
                lines = lrcContent.split('\n'),
                lyrics = [],
                timeRegEx = /\[(\d{2}):(\d{2}\.\d{1,7})]/,
                sameTimeIndex = self.getSameTimeIndex(lines, timeRegEx);
            if (sameTimeIndex !== -1) {
                //双语言
                for (let i = 0; i < lines.length - 1; i++) {
                    let matchF = lines[i].match(timeRegEx),
                        matchC = lines[i + 1].match(timeRegEx),
                        matchF2 = parseFloat(matchF[2]),
                        matchC2 = parseFloat(matchC[2]);
                    if (matchF2 > 0.5) {
                        matchF2 = matchF2 - 0.5; //歌词稍作提前突显处理
                    }
                    if (matchC2 > 0.5) {
                        matchC2 = matchC2 - 0.5; //歌词稍作提前突显处理
                    }
                    const timeF = parseInt(matchF[1], 10) * 60 + matchF2,
                        timeC = parseInt(matchC[1], 10) * 60 + matchC2,
                        textF = lines[i].replace(timeRegEx, '').trim(),
                        textC = lines[i + 1].replace(timeRegEx, '').trim(); // 去除时间戳部分
                    if (i < sameTimeIndex - 1) {
                        lyrics.push({
                            text: textF,
                            time: timeF,
                            endTime: timeC
                        });
                    } else {
                        lyrics.push({
                            text: textF,
                            secondText: textC,
                            time: timeF,
                            endTime: timeC
                        });
                        i++; // 跳过已匹配的中文行
                    }
                }
            } else {
                //中文
                for (let i = 0; i < lines.length; i++) {
                    const match = lines[i].match(timeRegEx);
                    if (match) {
                        let match2 = parseFloat(match[2]);
                        if (match2 > 0.5) {
                            match2 = match2 - 0.5; //歌词稍作提前突显处理
                        }
                        let time = parseInt(match[1], 10) * 60 + match2,
                            endTime = Infinity,
                            text = lines[i].replace(timeRegEx, '').trim(); // 去除时间戳部分
                        if (i < lines.length - 1) {
                            let matchEnd = lines[i + 1].match(timeRegEx),
                                matchEnd2 = parseFloat(matchEnd[2]);
                            if (matchEnd2 > 0.5) {
                                matchEnd2 = matchEnd2 - 0.5;
                            }
                            endTime = parseInt(matchEnd[1], 10) * 60 + matchEnd2;
                        }
                        lyrics.push({
                            text: text,
                            time: time,
                            endTime: endTime
                        });
                    }
                }
            }

            return lyrics;
        },
        onTimeUpdate(currentTime) {
            // 使用二分查找定位当前歌词行
            let low = 0;
            let high = this.lyrics.length - 1;
            while (low <= high) {
                const mid = Math.floor((low + high) / 2);
                if (currentTime >= this.lyrics[mid].time && currentTime < this.lyrics[mid].endTime) {
                    this.currentIndex = mid;
                    this.scrollToCurrentLyric();
                    return;
                } else if (currentTime < this.lyrics[mid].time) {
                    high = mid - 1;
                } else {
                    low = mid + 1;
                }
            }
        },
        scrollToCurrentLyric() {
            const currentLine = this.$refs.lyricLines[this.currentIndex];
            if (currentLine) {
                currentLine.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        },
        getSameTimeIndex(lines, timeRegEx) {
            for (let i = 0; i < lines.length - 1; i++) {
                const currentLine = lines[i],
                    nextLine = lines[i + 1],
                    currentMatch = currentLine.match(timeRegEx),
                    nextMatch = nextLine.match(timeRegEx);
                if (currentMatch && nextMatch) {
                    const currentTime = parseInt(currentMatch[1], 10) * 60 + parseFloat(currentMatch[2]),
                        nextTime = parseInt(nextMatch[1], 10) * 60 + parseFloat(nextMatch[2]);
                    if (currentTime === nextTime) {
                        return i;
                    }
                }
            }
            return -1; // 如果没有找到匹配的时间戳，返回 -1,则歌词是单语言
        }
    }
};
</script>
<style>
@import '@/assets/css/home.css';

.musicTitle {
    color: var(--primary-color) !important;
    font-size: 20px !important;
    font-weight: bold;
}

.lyrics {
    height: 100%;
    text-align: center;
    background-color: #f5f5f5;
    overflow-y: auto;
    padding-top: 5px;
    padding-bottom: 5px;
}

.none {
    display: grid;
    height: 90%;
    place-items: center;
}

.lyrics div {
    padding: 5px;
    font-size: 12px;
    color: gray;
}

.lyrics div.active .original {
    color: var(--primary-color);
    font-weight: bold;
    font-size: 16px;
}

.lyrics div.active .translation {
    color: darkorange;
    font-weight: bold;
    font-size: 14px;
}
</style>
